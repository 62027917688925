<template>
  <div class="buyRecord">
    <nav-bar title="购买记录" :clickLeft="onClickLeft" :hasLeftClick="true"></nav-bar>
    <div class="total">
      <div class="rest">套餐余量</div>
      <div class="flex">
        <div v-if="total.overTimeDays >= 0">到期时间：{{total.overTimeMax}} (剩余{{total.overTimeDays}}天)</div>
        <div v-else>到期时间： --</div>
      </div>
    </div>
    <div class="wrapper">
      <div v-if="recordNum.length > 0">
        <div class="list" v-for="(item, index) in recordNum" :key="index">
          <div class="flex">
            <div>
              <div class="num">{{ item.packageName }}</div>
              <div class="desc">{{item.packageDesc}}</div>
            </div>
            <div class="price">
              ￥{{ item.packagePrice }}
            </div>
          </div>
          <div class="flex data">
            <div>购买：{{item.buyTime}}</div>
            <div></div>
          </div>
        </div>
      </div>
      <div v-else>
          <van-empty :image="emptyImg" image-size="80" description="暂无购买记录" />
      </div>
    </div>
  </div>
</template>

<script>
import emptyImg from '@/assets/empty.png'
import NavBar from "@/components/NavBar.vue";
import { Empty } from "vant";
import { getBuyEsPackageList } from '@/api';
export default {
  name: "buyRecord",
  components: {
    NavBar,
    [Empty.name]: Empty,
  },
  data() {
    return {
      recordNum: [],
      total: {},
      emptyImg,
      back: false,//是否从购买跳过来
    };
  },
  methods: {
    onClickLeft() {
      console.log(this.back)
      if(this.back){
        var userId = sessionStorage.getItem('userId');
        var partyId = sessionStorage.getItem('partyId');
        var fromUrl = sessionStorage.getItem('fromUrl');
        var source = sessionStorage.getItem('source');
        this.$router.push({name: 'index',query: {userId,partyId,fromUrl,source}})
      }else{
        this.$router.go(-1)
      }
    },
    getList() {
      getBuyEsPackageList({ partyId: sessionStorage.getItem('partyId')})
      .then(res=>{
        console.log(res)
        this.total = res.data.data;
        this.recordNum = res.data.data.list
      })
    }
  },
  mounted() {
    this.getList()
  },
  beforeRouteEnter (to, from, next) {
    console.log(from)
    if(from.name == 'choiceSettle'){
      next(vm=>{
        vm.back = true;
      })
    }else{
      next();
    }
  }
};
</script>

<style lang="less" scoped>
.buyRecord {
  background: #f8f8f8;
  height: 100%;
  .total {
    background: url(../../assets/topBack.png) no-repeat 100%;
    background-position: bottom;
    background-size: cover;
    padding: 67.5px 0 30px 0;
    text-align: center;
    color: #b95d1f;
    border-radius: 0 0 10px 10px;
    .rest {
      font-size: 18px;
      font-weight: bold;
    }
    .flex {
      justify-content: center;
      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .wrapper {
    padding: 10px 10px 0;
    .list {
      margin-bottom: 10px;
      padding: 16px 15px 0 20px;
      background: #fff;
      border-radius: 10px;
      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .num {
          color: #161616;
          font-size: 15px;
          font-weight: bold;
        }
        .desc {
          color: #767676;
          font-size: 13px;
        }
        .price {
          color: #fe703f;
          font-size: 12px;
        }
      }
      .data {
        margin-top: 15px;
        border-top: 1px solid #f3f3f3;
        height: 49px;
        line-height: 49px;
        color: #262d3d;
      }
    }
  }
}
</style>